<template>
  <transition name="fade">
    <div v-if="open" class="popover-modal modal-post" :style="boxStyle">
      <div class="modal-shadow" @click="close"/>
      <div class="content-box rounded-sm relative flex flex-col items-center justify-center">
        <header class="flex h-base items-center px-sm">
          <slot name="header" />
          </header>
        <slot/>
      </div>
    </div>
  </transition>
</template>

<script>
import { getRootPath } from '@/lib/bookmarks'
import { bodyScrollGuard } from '@/lib/control-body-scroll'
import clickOutside from 'vue-click-outside'

export default {
  name: 'PopoverModalPost',
  directives: { clickOutside },
  props: {
    bookmark: null,
    boxStyle: Object
  },
  data: () => ({
    allowClickOutside: true,
    allowCoBack: true,
    open: true
  }),

  created () {
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        this.$emit('esc', e)
        this.close()
      }
    })
  },

  mounted () {
    this.setBodyScroll(this.open)
    this.setAllowClickOutside()
  },

  watch: {
    open (value) {
      this.setBodyScroll(value)
      this.setAllowClickOutside()
    }
  },

  methods: {
    goBack () {
      history.back()
    },

    close () {
      if (this.open && this.allowClickOutside) {
        this.open = false
        this.$emit('close')
        setTimeout(() => {
          const rootPath = getRootPath() || '/'
          this.$router.push(rootPath)
        }, 200)
      }
    },

    setBodyScroll (freeze) {
      if (freeze) {
        bodyScrollGuard.freezeBodyScroll()
      } else {
        bodyScrollGuard.releaseBodyScroll()
      }
    },

    releaseBgScroll () {
      this.setBodyScroll(false)
    },

    setAllowClickOutside () {
      if (this.open) {
        setTimeout(() => { this.allowClickOutside = true }, 100)
      } else {
        this.allowClickOutside = false
      }
    }
  },

  beforeDestroy () {
    bodyScrollGuard.releaseBodyScroll()
  }
}
</script>

<!--suppress CssInvalidAtRule -->
<style lang="scss">
  @import "../../../styles/vars";

  .popover-modal.modal-post {
    top: unset;
    left: unset;
    transform: none;

    .content-box {
      position: relative;
      height: 100%;
      //width: 100%;
      // max-height: calc(100vh - (#{$base-padding} - #{$border-thick-w}) * 2);
      //padding: $base-padding;
      // border: $border-thick-w solid #888888;

      & > header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        button {
          border-radius: 50%;
          background: rgba(240, 240, 240, 0.3);
        }
      }
    }
    .modal-shadow {
      background: rgba(215, 215, 215, 0.85);
    }
    .bg-semi-mix {
      background: #dfd8d9e8;
    }
  }
</style>
