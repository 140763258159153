<template>
  <popover-modal-post ref="modal" :box-style="boxStyle" class="page-popover fixed">
    <div class="content-wrap flex flex-col items-center justify-center">
      <transition>
        <page-view
          ref="post-view"
          class="content"
          @set-post="setPost"
          @allow-edit="setAllowEdit"
          @download-link="setDownloadLink"
          @allow-forward="setAllowForward"
          @allow-backward="setAllowBackward"
          @update-box="updateBoxStyle"
          @close="close"/>
      </transition>
    </div>
<!--    <template v-slot:header>-->
<!--      <edit-button-badge-->
<!--        class="overlay"-->
<!--        :allow-edit="allowEdit"-->
<!--        :download-link="downloadLink"-->
<!--        @open-editor="openEditor">-->
<!--      </edit-button-badge>-->
<!--    </template>-->
    <footer class="flex self-end">
      <button class="compact ml-auto" @click="close">
          <span class="material-icons text-3xl text-aba-blue">
            close
          </span>
      </button>
    </footer>
  </popover-modal-post>
</template>

<script>
import PopoverModalPost from './components/UI/PopoverModalPost'
import PageView from '@/views/PageView'

export default {
  name: 'PostPopover',
  components: { PageView, PopoverModalPost },
  data: () => ({
    post: null,
    allowEdit: false,
    allowForward: true,
    allowBackward: true,
    downloadLink: '',
    boxStyle: {}
  }),
  computed: {},
  methods: {
    setPost (post) {
      this.post = post || {}
    },

    close () {
      const func = (this.$refs.modal || {}).close
      if (typeof func === 'function') {
        func()
      }
    },

    async updateBoxStyle () {
      await this.$nextTick()
      const box = (this.$refs['post-view'] || {}).$el
      if (!box) return {}
      const { width, height } = box.getBoundingClientRect()
      this.boxStyle = {
        left: Math.round((window.innerWidth - width) / 2) + 'px',
        top: Math.round((window.innerHeight - height) / 2) + 'px'
      }
    },

    setAllowEdit (val) {
      this.allowEdit = !!val
    },

    setDownloadLink (val) {
      this.downloadLink = val
    },

    openEditor () {
      const func = (this.$refs['post-view'] || {}).openEditor
      if (typeof func === 'function') {
        func()
      }
    },

    goForward () {
      const func = (this.$refs['post-view'] || {}).goForward
      if (typeof func === 'function') {
        func()
      }
    },

    goBackward () {
      const func = (this.$refs['post-view'] || {}).goBackward
      if (typeof func === 'function') {
        func()
      }
    },

    setAllowForward (val) {
      this.allowForward = val
    },
    setAllowBackward (val) {
      this.allowBackward = val
    }
  }
}
</script>

<style lang="scss">
@import "../styles/vars";
.popover-modal.page-popover.modal-post {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-height: calc(var(--win-height, 100vh) - #{$base-padding * 2} - #{$base-size});

  .content-wrap {
    max-height: calc(var(--win-height, 100vh) - #{$base-padding} * 2);
    height: 100%;
  }
  .content{
    width: 100%;
    max-width: 65ch;
    padding: $base-padding;
    //height: calc(var(--win-height, 100vh) - #{$base-padding} * 2);
    max-height: 600px;
    overflow: auto;
    border: solid rgba(0, 0, 0, 0.41);
    border-width: 1px 0;
    background: var(--bgocolor-semi);

   // font-family: #{$font-family-sans-serif};

    .attachment-box {
    }
  }
  .modal-shadow {
    background: transparent;
  }
}
</style>
